<template>
  <div class="survey">
    <Header class="hide" />
    <Headers class="dispy" />
            <BannerImage rulename="overview_banner" />
            <div class="w">
                <Breadcrumb class="hide" :crumblist="crumbdata" />
                <el-row>
                    <el-col :md="16">
                        <div class="sur-left">
                             <div class="strip">
                                <div class="car">崇信概况</div>
                            </div>
                            <div class="doc" v-html="result.cont"></div>
                        </div>
                    </el-col>
                    <el-col :md="8">
                        <div class="hotels">
                            <div class="title">视频介绍</div>
                            <el-divider></el-divider>
                            <div class="tup">
                                <video
                                    :src="videopath"
                                    width="100%"
                                    height="100%"
                                    controls="controls"
                                    type="video/mp4"
                                    object-fit=" fill"
                                ></video>
                            </div>
                        </div>

            <div class="hotel">
              <div class="title">周边娱乐</div>
              <router-link to="/entertainment"><div class="titles">></div></router-link>
              <el-divider></el-divider>
              <div class="tup" v-for="item in yulelist" :key="item.id">
              <router-link :to="{path: '/entertdata', query:{id: item.id}}">
                <img
                  :src="`/jeecg-boot/${item.theLocal}`"
                  alt=""
                  class="bguan1"
                />
                <div class="name">
                  <span>{{ item.theTitle }}</span>
                </div>
              </router-link>
              </div>
            </div>
          </el-col>
        </el-row>
    </div>
    <Footer />
  </div>
  </div>
</template>
<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import Footer from "@/components/main/Footer";
import Pagi from "@/components/Pagi";
import {
  loadSingleConfig,
  loadPictureConfig,
  loadVideoConfig,
} from "@/api/api";
import { getAction } from "@/api/manage";
import BannerImage from "@/components/main/BannerImage";
export default {
  components: {
    Headers,
    Header,
    Footer,
    Pagi,
    Breadcrumb,
    BannerImage,
  },
  created() {
    this.loadDataInfo();
  },
  data() {
    return {
      result: "",
      videopath: "",
      image: "",
      yulelist: [],
      crumbdata: [
        { name: "崇信概况", url: "" },
       
      ],
    };
  },
  methods: {
    loadDataInfo() {
      loadSingleConfig({
        rule: "cx_overview",
      }).then((res) => {
        if (res.success) {
          this.result = res.result;
        }
      });
      // 查询视频
      loadVideoConfig({
        rule: "overview",
      }).then((res) => {
        if (res.success) {
          if (res.result.theType == "上传本地") {
            this.videopath ="/jeecg-boot/" + res.result.theLocal;
          } else if (res.result.theType == "外部链接") {
            this.videopath = res.result.theLocal;
          }
        }
      });
      // 查询周边娱乐
      getAction("/yule/tourYule/list", {
        pageSize: 3,
        column: "createTime",
        order: "desc",
      }).then((res) => {
        if (res.success) {
          this.yulelist = res.result.records;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.survey {
  .bars {
    width: 100%;
  }
  background: url(/img/white-bg.9771c1fc.png) no-repeat fixed top left;
  .sur-left {
    padding: 20px 20px 71px 20px;
    background-color: #ffffff;
    background-image: url("~@/assets/image/main/22.png");
    background-repeat: no-repeat;
    background-position: 0% 107%; /*这个是按从左往右，从上往下的百分比位置进行调整*/
    margin-bottom: 213px;
    .strip {
      height: 52px;
      background-color: #6EB40021;
    }
    .doc {
      padding: 20px;
      text-align: justify;
    }
  }
  .hotel {
    background-color: #ffffff;
    margin-bottom: 57px;
    padding-top: 32px;
    padding-bottom: 15px;
    margin-left: 24px;
    .title {
      font-family: MicrosoftYaHei;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #999999;
      margin-left: 22px;
      padding-bottom: 17px;
    }
    .titles {
      font-family: MicrosoftYaHei;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #999999;
      margin-left: 22px;
      padding-bottom: 17px;
      float: right;
      margin-top: -41px;
      margin-right: 21px;
    }
    .el-divider--horizontal {
      width: 91%;
      margin: 0 0 0 16px;
    }
    .tup {
    margin: 15px 15px 0 15px;
      position: relative;
      height: 217px;
      width: 337px;
      overflow: hidden;
      img {
        width: 337px;
        height: 217px;
         transition: all 0.6s;
          &:hover {
            transform: scale(1.1);
          }
      }
      .name {
        height: 35px;
        background-color: #00000096;
        text-align: center;
        position: relative;
        top: -39px;
        span {
          display: block;
          font-size: 16px;
          line-height: 29px;
          color: #ffffff;
          padding: 0 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .hotels {
    background-color: #ffffff;
    margin-bottom: 25px;
    padding-top: 32px;
    padding-bottom: 15px;
    margin-left: 24px;
    .title {
      font-family: MicrosoftYaHei;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #999999;
      margin-left: 22px;
      padding-bottom: 17px;
    }
    .titles {
      font-family: MicrosoftYaHei;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #999999;
      margin-left: 22px;
      padding-bottom: 17px;
      float: right;
      margin-top: -41px;
      margin-right: 21px;
    }
    .el-divider--horizontal {
      width: 91%;
      margin: 0 0 0 16px;
    }
    .tup {
      padding: 15px 15px 0 15px;
      position: relative;
      height: 217px;
      img {
        width: 337px;
        height: 217px;
      }
      .name {
        height: 35px;
        background-color: #00000096;
        text-align: center;
        position: relative;
        top: -39px;
        span {
          font-family: MicrosoftYaHei;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 29px;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
